<template>
  <div>
    <header-slot no-border-bottom />
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: 'correspondence-checks-new' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >NEW
        <span v-if="countNew > 0" class="ml-1">
          <feather-icon
            icon
            :badge="countNew > 99 ? '99+' : countNew"
            badge-classes="badge-danger"
          />
        </span>
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'correspondence-checks-sent' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >SENT
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'correspondence-checks-cancelled' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >CANCELLED
      </b-nav-item>
    </b-nav>

    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name" />
    </b-card>
  </div>
</template>

<script>
// STORE
import { mapGetters, mapActions } from "vuex";
import { canNavigateDirect } from "@/libs/acl/routeProtectionDirect";
import { getUserData } from "@/auth/utils";
export default {
  computed: {
    ...mapGetters({
      G_COUNTER_CHECK: "CheckStore/G_COUNTER_CHECK",
    }),
    isNewTab() {
      return this.$route.name == this.$route.matched[1].meta.routeNew;
    },
    isSentTab() {
      return this.$route.name == this.$route.matched[1].meta.routeSent;
    },
    isCancelledTab() {
      return this.$route.name == this.$route.matched[1].meta.routeCancelled;
    },
    countNew() {
      if (Array.isArray(this.G_COUNTER_CHECK)) {
        const item = this.G_COUNTER_CHECK.find(
          (item) => item.name_status === "SEND TO CORRESPONDENCE"
        );
        return item ? item.count : 0;
      }
      return 0;
    },
  },
  methods: {
    ...mapActions({
      A_COUNTER_CHECK: "CheckStore/A_COUNTER_CHECK",
    }),
  },
  async created() {
    await this.A_GET_USERS_TO_MESSAGE();
  },
};
</script>
